<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form @submit.prevent="handleSubmit(submitHandler)">
      <b-form-group
        label="Период"
        label-for="date_start"
      >
        <b-input-group>
          <b-form-input
            id="date_start"
            type="date"
            :value="formData.date__gte"
          />
          <b-form-input
            type="date"
            :value="formData.date__lte"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="Категория"
      >
        <v-select
          v-model="formData.type"
          :options="$store.getters['directories/operation']"
          :reduce="item => item.code"
          placeholder="Категория"
          label="title"
        />
      </b-form-group>
      <b-form-group
        label="Источник"
      >
        <v-select
          v-model="formData.out_key"
          :options="$store.getters['directories/outKey']"
          :reduce="item => item.code"
          placeholder="Источник"
          label="title"
        />
      </b-form-group>
      <div class="d-flex justify-content-center mt-2">
        <b-button
          variant="primary"
          class="mr-2"
          type="submit"
        >
          Применить
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BButton,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          date__gte: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          date__lte: moment().format('YYYY-MM-DD'),
          type: '',
          out_key: '',
        }
      },
    },
  },
  data() {
    return {
      formData: {
        date__gte: null,
        date__lte: null,
        type: '',
        out_key: '',
      },
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.valueUpdatedHandler()
      },
    },
  },
  mounted() {
    this.valueUpdatedHandler()
  },
  methods: {
    valueUpdatedHandler() {
      this.formData = { ...this.value }
    },
    submitHandler() {
      this.$emit('submit', this.formData)
    },
  },
}
</script>
