<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <v-table
        path="operations"
        :fields="tableColumns"
        table-sm
        @create="createOperationHandler"
      >
        <template #cell(serial)="data">
          <v-table-serial-column :data="data" />
        </template>
        <template #cell(actions)="data">
          <v-table-actions-column
            :del="false"
            :edit="false"
            @view="$router.push({ name: 'operation-view', params: { id: data.item.id }})"
          />
        </template>
      </v-table>
    </b-card>
    <b-modal
      id="operation-create"
      title="Добавить операцию"
      hide-footer
    >
      <operation-create-form />
    </b-modal>
  </div>
</template>
<script>
import { BCard, BModal } from 'bootstrap-vue'
import VTable, {
  VTableSerialColumn,
  VTableActionsColumn,
} from '@/views/components/vtable'
// eslint-disable-next-line import/no-cycle
import OperationsFilterForm from '@/views/operations/components/OperationsFilterForm.vue'
// eslint-disable-next-line import/no-cycle
import { tableColumns } from './operations_use'

export default {
  components: {
    OperationsFilterForm,
    BCard,
    BModal,
    VTable,
    VTableSerialColumn,
    VTableActionsColumn,
    OperationCreateForm,
  },
  data() {
    return {
      tableColumns,
      tableActions: ['view'],
    }
  },
  methods: {
    openCreateOperationModal() {
      this.$bvModal.show('operation-create')
    },
    createOperationHandler() {
      this.openCreateOperationModal()
    },
  },
}
</script>
