import store from '@/store'

// eslint-disable-next-line import/prefer-default-export
export const tableColumns = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'driver',
    label: 'ФИО',
    sortable: true,
    formatter: driver => `${driver.last_name} ${driver.first_name} ${driver.middle_name ?? ''}`,
  },
  {
    key: 'date',
    label: 'Дата',
    sortable: true,
  },
  {
    key: 'amount',
    label: 'Сумма',
    sortable: true,
  },
  {
    key: 'type',
    label: 'Категория',
    sortable: true,
    formatter: type => store.getters['directories/operation'].filter(item => item.code === type).pop()?.title ?? '-',
  },
  {
    key: 'out_key',
    label: 'Источник',
    sortable: true,
    formatter: key => store.getters['directories/outKey'].filter(item => item.code === key).pop()?.title ?? '-',
  },
  {
    key: 'balance',
    label: 'Баланс',
  },
  {
    key: 'actions',
    label: '',
  },
]
